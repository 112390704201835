import React from 'react';

import { BREAKPOINT, COLOR } from '@latitude/core/utils/constants';
import { Box } from '@latitude/box';
import { Hero } from '@latitude/hero';
import { Lframe } from '@latitude/lframe';
import { Metadata } from '@latitude/metadata';
import { TabNavigation } from '@latitude/tab-navigation';

import Layout from '@/components/layout';
import { SITE_URL, PRODUCT_NAME } from '@/utils/constants';
import TabNavData from '@/data/json/Calculators/iif-calculator.json';
import TabStyle from './_TabStyle';
import { InstalmentInterestFreeCalculator } from '@/components/instalment-interest-free-calculator';
import GemMicrositeHeader from '../_gem-microsite-header';

const IIFCalculator = ({ location }) => {
  return (
    <Layout location={location}>
      <Metadata
        title="Interest Free Payment Plan Calculator | Gem Visa"
        description="Calculate repayments for purchases using Equal Monthly payment plans"
        keywords={PRODUCT_NAME}
        canonical={`${SITE_URL}${location.pathname}`}
      />
      <Box
        backgroundColor={COLOR.GREY6}
        css={`
          .microsite-header {
            z-index: 2000 !important;
          }
        `}
      >
        <div
          css={`
            position: relative;
            margin-top: 95px;
            @media (min-width: ${BREAKPOINT.LG}) {
              margin-top: 120px;
            }
          `}
        >
          <Lframe
            css={`
              background-image: url(${require('../images/lframe-calculator.png')
                .default});
              background-position: 25px 0;
              z-index: auto;

              @media (min-width: ${BREAKPOINT.MD}) {
                background-position: 54vw 0;
              }

              @media (min-width: ${BREAKPOINT.LG}) {
                background-size: 1250px auto;
                background-position: 50vw 0;
              }

              @media (min-width: ${BREAKPOINT.XL}) {
                background-position: 53vw 0;
              }
              @media (min-width: ${BREAKPOINT.LG}) {
                background-size: 880px auto;
              }
            `}
          />

          <Hero
            title="Interest Free payment plan calculator"
            text="Calculate repayments for purchases using Equal Monthly payment plans"
          />
        </div>
        <GemMicrositeHeader />
      </Box>

      <TabStyle backgroundColor={COLOR.GREY6}>
        <TabNavigation
          basepath="/credit-cards/gem-visa/iifcalculator"
          data={TabNavData.items}
        />
      </TabStyle>
      <Box.Section
        backgroundColor={COLOR.GREY6}
        id="calculator"
        css={`
          @media (min-width: ${BREAKPOINT.MD}) {
            margin-top: 48px;
          }
          @media (min-width: ${BREAKPOINT.LG}) {
            margin-top: -96px;
          }
          @media (min-width: ${BREAKPOINT.XL}) {
            margin-top: -40px;
          }
        `}
      >
        <InstalmentInterestFreeCalculator
          annualFee={0}
          establishmentFee={0}
          minimumAmount={500}
          noMinimumAmount={true}
          isGemVisaMac={true}
          noMinPurchaseText={true}
          helperTextForAmmount={
            'Minimum purchase amount applies. See current promotions for terms and conditions.'
          }
          monthlyFee={10.95}
          monthlyTerms={[6, 12, 18, 24, 34, 36, 39, 40, 48, 50, 60]}
          importantInfo={[
            'These repayments are for Equal Monthly payment plans only and do not take into account any other purchases or balances.',
            'The monthly, weekly and daily repayments are approximate only.',
            'Monthly repayments are required and the exact amount will be shown on your statement.',
            'Minimum spend amount applies. See current promotions for terms and conditions.',
            'Plans only available during specific promotional periods.'
          ]}
        />
      </Box.Section>
    </Layout>
  );
};

export default IIFCalculator;
